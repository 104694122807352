import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { globalGetService,globalExportService } from '../../../utils/globalApiServices';
import { Grid, Table, TableCell, TableHead, TableRow, TableBody, Paper,TableSortLabel } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import config from '../../../config'
import { downloadFileType,getLocalStorageInfo,Comma_format } from '../../../utils';
import { EmptyCollection, PageLoader } from '../../../shared_elements';
import Pagination from '../../../shared_elements/components/Pagination';
import FilterComponent from '../../../shared_elements/components/filter_component';
import ExportMenu from '../../../shared_elements/components/ExportMenu';


const LimiterDue = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeltonLoader, setSkeltonLoader] = useState(false);
    const [llpData, setLLPData] = useState({ data: [], pagination: {} });
    const [filter, setFilter] = useState({})
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });

    useEffect(() => {
        smbcChartLLPLimiter({ per_page: 20 })
    }, []);
    const createSortHandler = (key) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        smbcChartLLPLimiter({ ...filter, ...sortQuery, page: 1, per_page: llpData?.data?.pagination.per_page }, 'pageLoader');
    }


    const smbcChartLLPLimiter = (query, loader) => {
        loader === "pageLoader" ? setLoading(true) : setSkeltonLoader(true);
        globalGetService(`camo/smbc_dashboard/limiter/`, { ...query })
            .then((response) => {
                setLLPData(response.data);
                setFilter(query)
                loader === "pageLoader" ? setLoading(false) : setSkeltonLoader(false);
            }).catch((error) => {
                console.error('Error fetching chart data:', error);
                setLoading(false);
                setSkeltonLoader(false);
            });
    }
    const exportLimiter = (extension) => {
        setLoading(true);
        let queryParams = {}
        if (sortInfo?.sort === '' &&  sortInfo?.sort_by === '') {
            queryParams= Object.assign({}, filter);
        }else{
            queryParams= Object.assign({}, filter,sortInfo);
        }
        globalExportService(`camo/smbc_dashboard/limiter/`, { ...queryParams, download: extension})
            .then(response => {
                setLoading(false);
                enqueueSnackbar("Download of Limiter Due List successfull", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                downloadFileType(response.data, `Limiter Due_List`, extension)
            });
    }

    const FilterOptions = {
        'gte': {
            'inputType': 'number',
            'title': 'Value From',
        },
        'lte': {
            'inputType': 'number',
            'title': 'Value To',
        },
       
    }
    const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532)

    const rowClick = (e,item) => {
        e.preventDefault();
        e.stopPropagation();
        return (smbcInstance ? window.open(`${item?.id}/${item?.asset_type}/op_history/list`,'_blank'): window.open(`${item?.id}/${item?.asset_type}/ad/list`,'_blank'))
    }

    const filterObj = Object.assign({}, filter, delete filter?.page, delete filter?.sort, delete filter?.sort_by)
    let filterOptions = Object.assign({}, FilterOptions);

    return (
        <>
            {skeltonLoader ? (
                <Skeleton variant="rect" animation='wave' height={400} />
            ) : <> 
                    <Paper square className="dashboard-chart">
                        <div className='dashboard-chart-title'>
                            <Grid container spacing={0}>
                            <Grid  item  xs={12} sm={12} md={12}>
                                    <ul className='list-inline' style={{ float: 'left'}}>
                                    <li className='list-inline-item' style={{ padding: '2px',}}>
                                    <h5>Limiter Due</h5>
                                        </li>
                                        <li className='list-inline-item' >
                                            <FilterComponent
                                                filter={filterObj}
                                                filterMenu={filterOptions}
                                                getResponseBack={(applyFilter) => smbcChartLLPLimiter({ ...applyFilter, per_page: 20 }, 'pageLoader')}
                                            />
                                        </li>
                                    </ul>
                                    <ul className='list-inline' style={{ float: 'right' }}>
                                        <li className='list-inline-item' >
                                            <ExportMenu
                                                exportReportFn={(file) => exportLimiter(file.extension)}
                                                files={[{ title: 'EXCEL', extension: 'xls' }]}
                                            />
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                <Paper className='major-assemblies-list' square>
                    <div style={{ overflow: 'auto', height:!llpData?.data?.results?.length ? "0px": "408px"}}>
                        <Table className='mui-table-format' stickyHeader={true}>
                            <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel active={sortInfo.sort === 'engine_type' ? true : false} direction={sortInfo.sort === 'engine_type' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('engine_type')} className='col-align-center'>
                                                Engine
                                            </TableSortLabel></TableCell>
                                        <TableCell>
                                            <TableSortLabel active={sortInfo.sort === 'esn' ? true : false} direction={sortInfo.sort === 'esn' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('esn')} className='col-align-center'>
                                                ESN
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell >
                                            <TableSortLabel active={sortInfo.sort === 'lessee' ? true : false} direction={sortInfo.sort === 'lessee' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('lessee')} className='col-align-center'>
                                                Lessee
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell  >
                                            <TableSortLabel active={sortInfo.sort === 'operator' ? true : false} direction={sortInfo.sort === 'operator' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('operator')} className='col-align-center'>
                                                Operator
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell style={{minWidth:"105px"}}>
                                            <TableSortLabel active={sortInfo.sort === 'limiter_type' ? true : false} direction={sortInfo.sort === 'limiter_type' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('limiter_type')} className='col-align-center'>
                                                Limiter Type
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell >
                                            <TableSortLabel active={sortInfo.sort === 'value' ? true : false} direction={sortInfo.sort === 'value' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('value')} className='col-align-center'>
                                                Value
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                            </TableHead>
                            <TableBody>
                                {llpData?.data?.results?.map((item, index) =>
                                    <TableRow style={{ cursor: "pointer" }} onClick={(e) => rowClick(e,item)}>
                                        <TableCell >{item?.engine_type || "--"}</TableCell>
                                        <TableCell >{item?.esn || "--"}</TableCell>
                                        <TableCell >{item?.lessee || "--"}</TableCell>
                                        <TableCell >{item?.operator || "--"}</TableCell>
                                        <TableCell  >{item?.limiter_type || "--"}</TableCell>
                                        <TableCell >{item.value ? Comma_format(parseInt(item.value)) : item.value === null ? 'N/A' : 0}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <Pagination
                        pagination={llpData?.data?.pagination}
                        onChangePage={(event, newPage) => smbcChartLLPLimiter({ ...filter, page: newPage + 1, per_page: llpData?.data?.pagination?.per_page }, 'pageLoader')}
                        onChangeRowsPerPage={(event) => smbcChartLLPLimiter({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                    />
                    {!llpData?.data?.results?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title={'No records found'} /></div> : null}
                </Paper>
            </>
            }
            {isLoading ? <PageLoader /> : null}
        </>
    );
};

export default withRouter(LimiterDue);