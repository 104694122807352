import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField, Grid, Paper } from '@material-ui/core';
import { globalGetService } from '../../../utils/globalApiServices';
import Highcharts from 'highcharts';
import { Skeleton } from '@material-ui/lab';
import { PageLoader } from '../../../shared_elements';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

const ShopVisitChart = ({ underlyingData }) => {
    const [pageLoader, setPageLoader] = useState(false);
    const [skeltonLoader, setSkeltonLoader] = useState(false);
    const [SvChart, setSvChart] = useState(null);
    const [filter, setFilter] = useState({});

    useEffect(() => {
        svChart();
    }, []);


    const svChart = (query, loader) => {
        loader === "pageLoader" ? setPageLoader(true) : setSkeltonLoader(true);
        globalGetService(`camo/smbc_dashboard/sv_date/`, { ...query })
            .then((response) => {
                setSvChart(response.data);
                loader === "pageLoader" ? setPageLoader(false) : setSkeltonLoader(false);
            }).catch((error) => {
                console.error('Error fetching chart data:', error);
                setPageLoader(false);
                setSkeltonLoader(false);
            });
    }

    const colorSV = [{ color: '#39318c' }, { color: '#a3d10d' }, { color: '#f5d189' }, { color: '#3db1f5' }, { color: '#16c739' }, { color: '#ed7366' }]
    const SVoption = {
        chart: {
            type: 'column',
            scrollablePlotArea: {
                minWidth: filter?.value > 40 ? 3500 : filter?.value > 25 ? 2000 : filter?.value > 15 ? 1500 : filter?.value > 8 ? 900 : 600
            },
        },
        colors: colorSV.map(item => item.color),
        title: {
            text: '',
            align: '',
        },
        xAxis: {
            categories: SvChart?.monthly_summary.map(item => [item?.date_range]),
            crosshair: true,
            accessibility: {
                description: 'Shop Visit'
            },
            colorByPoint: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                point: {
                    events: {
                        click: function (oEvent) {
                            const pointCategory = oEvent.point.category || oEvent.point.name;
                            if (pointCategory) {
                                underlyingData(pointCategory, 'sv');
                            } else {
                                console.error("Point category or name is not defined");
                            }
                        }
                    }
                }

            }
        },
        series: [
            {
                name: 'Shop Visits',
                data: SvChart?.monthly_summary.map(item => [item?.count]),
                colorByPoint: true
            }
        ],
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            filename: 'Shop_Visits_Due_date',
            chartOptions: {
                title: {
                    text: 'Shop Visits Due Date',
                    align: 'center'
                },
            },
        },
    };

    const onFieldChange = (e, data) => {
        const value = data ? data?.value : '';
        setFilter(data);
        const query = value ? { upto_next_month: value } : {};
        svChart(query, 'pageLoader');
    };
    const getMonths = () => {
        const months = [];
        const currentDate = new Date();
        for (let i = 0; i < 60; i++) {
            const newDate = new Date(currentDate);
            newDate.setMonth(currentDate.getMonth() + i);
            const monthLabel = newDate.toLocaleString('default', { month: 'short' }) + ' ' + newDate.getFullYear();
            months.push({ label: monthLabel, value: i + 1 });
        }
        return months;
    };

    return (
        <>
            {skeltonLoader ? (
                <Skeleton variant="rect" animation='wave' height={400} />
            ) : (
                <> <Paper square className="dashboard-chart" style={{ borderRadius: 0,}}>
                    <div className='dashboard-chart-title' style={{padding:'15px'}}>
                        <h5>Shop Visits Due Date</h5>
                    </div>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4} style={{ padding: '0px 10px' }}>
                            <Autocomplete
                                options={getMonths() ? getMonths() : []}
                                getOptionLabel={(option) => option?.label || ''}
                                id="upto_next_month"
                                value={filter}
                                className='fleet-due'
                                onChange={(e, value) => onFieldChange(e, value)}
                                renderInput={(params) => (
                                    <TextField
                                        variant="outlined"
                                        {...params}
                                        InputLabelProps={{ shrink: true }}
                                        label="Filter by Till Month"
                                        margin="normal"
                                        fullWidth
                                        placeholder="Select Month"
                                    />
                                )}
                            />

                        </Grid>
                    </Grid>

                    <HighchartsReact highcharts={Highcharts} options={SVoption} />
                </Paper>
                </>
            )
            }
            {pageLoader ? <PageLoader /> : null}
        </>
    );
};

export default withRouter(ShopVisitChart);
