import { React, useState, useEffect } from 'react'
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from "@material-ui/core"
import InfoIcon from '@material-ui/icons/Info';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import { globalPutService, globalPostService, globalGetService } from '../../../utils/globalApiServices';
import { PageLoader } from '../../../shared_elements';
import { checkApiStatus } from '../../../utils'
import { configDataObj } from '.';

function AddEditConfigure({ toggleModalFn, configData, modeType, getResponseBack }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(configData ? configData : configDataObj)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [engineType, setEngineType] = useState([])
    const [egineFamily, setEngineFamily] = useState([])

    useEffect(() => {
        getEngineFamily()
        if (data?.engine_family) {
            onChangeEngineFamily('', data?.engine_family)
        }
    }, [])

    const onFieldChange = (keyParam, value, extra) => {
        setData({ ...data, [keyParam]: value });

    }
    const onChangeEngineFamily = (keyParam, value) => {
        if (keyParam === 'engine_family' && !value) {
            setData({ ...data, ['engine_family']: '', ['engine_type']: '' });
            setEngineType('')
        } else {
            globalGetService(`camo/engine_dropdown/${value?.id}/`,)
                .then(response => {
                    if (checkApiStatus(response)) {
                        setData({
                            ...data,
                            [keyParam]: value,
                            engine_type: response?.data.engine_type.filter(item1 =>
                                configData?.engine_type.some(item2 => item1?.id === item2?.id && item1?.name === item2?.name)
                            )
                        });
                        setEngineType(response?.data?.engine_type)
                    } else {
                        setData({ ...data, [keyParam]: value });
                    }
                })
        }
    }
    const onRestErrorKey = (keyParam) => {
        setError({ [keyParam]: '' })
    }
    const getEngineFamily = () => {
        globalGetService(`camo/engine_dropdown/`)
            .then((response) => {
                if (response.status === 200) {
                    setEngineFamily(response?.data?.engine_family)
                }
            })
    }

    const onAddNewAsset = () => {
        let validationInputs = {}
        validationInputs = {
            ...validationInputs,
            revision_number: data?.revision_number ? '' : "Please Enter Revision Number",
            engine_family: data?.engine_family ? '' : "Please Select Engine Family",
            engine_type: data?.engine_type?.length > 0 ? '' : "Please Select Engine Type",
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/disclemer_revision/${payload.id}/`, { ...payload, status: true, engine_type: payload?.engine_type ? payload?.engine_type?.map(item => item?.id).join(',') : '', })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                globalPostService(`camo/disclemer_revision/`, { ...payload, status: true, engine_type: payload?.engine_type ? payload?.engine_type?.map(item => item?.id).join(',') : '', })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })

            }
        } else {
            setError(validationInputs)
        }
    }
    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Edit Configure" : "Add Configure"} </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={3} sm={3} >
                            <TextField
                                required
                                variant="outlined"
                                margin='normal'
                                label="Revision Number"
                                id="revision_number"
                                fullWidth
                                error={error?.revision_number ? true : false}
                                helperText={error?.revision_number ? error?.revision_number : ""}
                                inputProps={{ maxLength: 50 }}
                                value={data?.revision_number}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => { onFieldChange("revision_number", e.target.value); onRestErrorKey('revision_number') }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="engine_family"
                                options={egineFamily || []}
                                getOptionLabel={(option) => option?.name}
                                value={data?.engine_family || []}
                                margin='normal'
                                onChange={(e, value) => { onChangeEngineFamily("engine_family", value); onRestErrorKey('engine_family') }}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                                Engine Family
                                                <Tooltip placement='right' title="Select Engine Family to apply all Engine Types under the family to Engine Type" arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        error={error?.engine_family ? true : false}
                                        helperText={error?.engine_family ? error?.engine_family : ""}
                                        margin='normal'
                                        placeholder="Select Engine Family"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                            <Autocomplete
                                id="engine_type"
                                options={engineType || []}
                                getOptionLabel={(option) => option?.name}
                                value={data?.engine_type || []}
                                margin='normal'
                                multiple={true}
                                disableCloseOnSelect={true}
                                onChange={(e, value) => { onFieldChange("engine_type", value); onRestErrorKey('engine_type') }}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        error={error?.engine_type ? true : false}
                                        helperText={error?.engine_type ? error?.engine_type : ""}
                                        label="Engine Type"
                                        margin='normal'
                                        placeholder="Select Engine Type"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={5} sm={5} >
                            <TextField
                                variant="outlined"
                                margin='normal'
                                label="Disclaimer Message"
                                id="disclemer_message"
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                                value={data?.disclemer_message}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("disclemer_message", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {isLoading ? <PageLoader /> : null}
        </>
    )
}
export default AddEditConfigure;