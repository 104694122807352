import React, { Component, Fragment } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip, IconButton, Dialog, } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { getAircraftDetailApi } from "./apiServices"
import { permissionCheckFnforCamo} from "../../../src/utils"
import { withSnackbar } from "notistack"
import { connect } from "react-redux"
import moment from 'moment';
import { getAircraftTypesAc, getEngineTypesAc, getLesseeListAc, getOperatorListAc, getRegionListAc, getPlatformConstantsAc, getPortfolioListAc, getAircraftMntProgramAc, getLeaseStatus, getSubStatus, } from "../../shared_elements/actionCreators"
import { trackActivity } from "../../utils/mixpanel"
import BasicDetailForm from "./BasicDetailForm"
import { isMobile } from "react-device-detect"
import EngineDetailForm from "./EngineDetailForm"
import { engineType } from "../home/apiServices"
import { getTechSpecDetailApi } from "./apiServices"
import { getLocalStorageInfo,Comma_format } from "../../../src/utils"
import config from "../../config";
import { dayMonthDateFormat } from "../../constants"
import EngOnOffWingList from "./EngOnOffWingList"


class BasicDetailHeader extends Component {
	constructor(props) {
		super(props)
		this.state = {
			headerData: {},
			LHData: {},
			RHData: {},
			formTitle: "",
			openEditForm: false,
			openEditFormEngine: false,
			ownersList: [],
			basicDetailData: {}
		}
		 this.getTechSpecDetailApi = getTechSpecDetailApi.bind(this)
	}

	mainHeaderData = async (props) => {
		try {
			const response = await getAircraftDetailApi(props);
			const data = response.data.data;
			let tempLHdata = {};
			let tempRHdata = {};

			if (data) {
				tempLHdata = data.engine_detail?.length > 0 ? data.engine_detail.find((x) => x.position.value === 1 || x.position.value === 3) : {};
				if (tempLHdata) {
					this.setState({ LHData: tempLHdata });
				}

				tempRHdata = data.engine_detail?.length > 0 ? data.engine_detail.find((x) => x.position.value === 2 || x.position.value === 4) : {};
				if (tempRHdata) {
					this.setState({ RHData: tempRHdata });
				}
			}

			this.setState({ headerData: data });
			this.props.func(data.slug, data.tsn, data.csn, data.date_of_manufacture,data?.thrust_details);
			this.props.uuid(data?.record_folders?.technical_records);
			
			if (this.props.module === 'util' || this.props.module === 'Op') {
				this.props.engineType(data);
			}
			await this.getTechSpecDetailApi(data);
			
			setTimeout(()=>{
				const {basicDetailData } = this.state
				if (this.props.module === 'util') {
					this.props.engineType(data,basicDetailData);
				}
				if (this.props.module === 'sv') {
					this.props.basicData(data,basicDetailData);
				}
			},2000)
			
			
		} catch (error) {
			console.error("Error in mainHeaderData:", error);
		}
	};

	componentDidMount() {
		// this.getTechSpecDetailApi()
		this.mainHeaderData(this.props)
		this.props.getPlatformConstants()
		this.props.getLesseeList()
		this.props.getOperatorList()
		this.props.getRegionList()
		this.props.getPortfolioList()
		this.props.getEngineTypes()
		this.props.getAircraftTypes()
		this.props.getLeaseStatus()
		this.props.getSubStatus()
		trackActivity("Page Visited", {
			page_title: "Asset Listing",
		})
	}

	handleEditRow = () => {
		this.setState({ formTitle: "Update Assets" })
		this.setState({ openEditForm: true })
	}

	closeUpdateForm = () => {
		this.setState({ openEditForm: false })
	}
	render() {
		const { asset, assetType } = this.props?.props?.match?.params
		const { ownersList, headerData, basicDetailData } = this.state
		const { portfolios, aircraftTypes, pltConstants, lessees, operators, aircraftMntGrp, leaseStatus, subStatus,svData } = this.props
		return (
			<Fragment>
				<div id="AmpTableHeader">
					<Accordion defaultExpanded>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon className="p-5" />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>Basic Details</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container>
								<Grid
									className="left-panel"
									item
									xs={12}
									style={{ backgroundColor: " #efefef" }}
								>
									<Table className="table-wrapper">
										<TableHead>
											<TableRow>
												<TableCell>{assetType == 1 ? "Aircraft Type/Model" : "Engine Type/Model"}</TableCell>
												<TableCell>{assetType == 1 ? "MSN" : "ESN"}</TableCell>
												{assetType == 1 && <TableCell>Registration</TableCell>}
												<TableCell>Date of Manufacture</TableCell>
												<TableCell> {assetType == 1 ? "A/C TSN" : "Engine TSN"}</TableCell>
												<TableCell> {assetType == 1 ? "A/C CSN" : "Engine CSN"}</TableCell>
												{getLocalStorageInfo()?.user?.security_groups?.find(item =>item?.id === 29) ?
												 <div id="edit-basic-detail" className="d-flex">
													<span class="editBasicDetail">
														{/* {permissionCheckFnforCamo({ primaryKey: "asset_management", keyIndex: "U", }) ? */}
														{/* {checkPermission('camo','asset_management','U') ? */}
															<Tooltip title="Edit">
																<IconButton
																	className="edit-asset-icon"
																	onClick={(e) => {
																		this.handleEditRow(e)
																	}}
																>
																	<CreateOutlinedIcon />
																</IconButton>
															</Tooltip>
														{/* : null} */}
													</span>
												</div> : null}
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell className="font-b">
													{assetType == 1 ? this.state.headerData?.aircraft_type_name : this.state.headerData.engine_type_name}
												</TableCell>
												<TableCell className="font-b">
													{assetType == 1 ? this.state?.headerData?.msn : this.state?.headerData?.esn}
												</TableCell>
												{assetType == 1 && <TableCell className="font-b">
													{this.state.headerData.current_registration_number ? this.state.headerData.current_registration_number : " -- "}
												</TableCell>}
												<TableCell className="font-b">
													{this.state.headerData.date_of_manufacture ?  moment(this.state.headerData.date_of_manufacture).format(dayMonthDateFormat) : " -- "}
												</TableCell>
												<TableCell className="font-b">
													{this.state.headerData.tsn ? Comma_format((this.state.headerData.tsn).toFixed(2)) : "--"}
												</TableCell>
												<TableCell className="font-b">
													{this.state.headerData.csn ? Comma_format(parseInt(this.state.headerData.csn)) : "--"}
												</TableCell>
											</TableRow>
										</TableBody>
										{assetType == 1 && <TableHead className="table-head">
											<TableRow>
												<TableCell>LH Engine Type(Serial No)</TableCell>
												<TableCell>LH Engine TSN</TableCell>
												<TableCell>LH Engine CSN</TableCell>
												<TableCell>RH Engine Type/Serial No</TableCell>
												<TableCell>RH Engine TSN</TableCell>
												<TableCell>RH Engine CSN</TableCell>
											</TableRow>
										</TableHead>}
										{assetType == 2 && <TableHead className="table-head">
											<TableRow>
												<TableCell>TSLSV</TableCell>
												<TableCell>CSLSV</TableCell>
												<TableCell>LLP Limiter(s)</TableCell>
												<TableCell>Soft-time Limiter(s)</TableCell>
												<TableCell>Op. Thrust (Current)</TableCell>
											</TableRow>
										</TableHead>}

										{assetType == 1 && <TableBody>
											<TableRow>
												<TableCell className="font-b">
													{this.state?.LHData?.engine_type?.name ? `${this.state.LHData?.engine_type?.name + " "}(${this.state.LHData?.esn})` : " -- "}
												</TableCell>
												<TableCell className="font-b">
													{this.state.LHData && this.state.LHData.tsn ? Comma_format(this.state.LHData.tsn) : " -- "}
												</TableCell>
												<TableCell className="font-b">
													{this.state.LHData && this.state.LHData.csn ? Comma_format(parseInt(this.state.LHData.csn)) : " -- "}
												</TableCell>
												<TableCell className="font-b">
													{this.state?.RHData?.engine_type?.name ? `${this.state.RHData?.engine_type?.name + " "}(${this.state.RHData?.esn})` : " -- "}
												</TableCell>
												<TableCell className="font-b">
													{this.state.RHData && this.state.RHData.tsn ? Comma_format(this.state.RHData.tsn) : " -- "}
												</TableCell>
												<TableCell className="font-b">
													{this.state.RHData && this.state.RHData.csn ? Comma_format(this.state.RHData.csn) : " -- "}
												</TableCell>
											</TableRow>
										</TableBody>}
										{assetType == 2 && <TableBody>
											<TableRow>
												<TableCell className="font-b">
													{headerData?.tslv > 0 ? Comma_format((headerData?.tslv).toFixed(2)) : 0}
												</TableCell>
												<TableCell className="font-b">
													{headerData?.cslv > 0  ? Comma_format(parseInt(headerData?.cslv)) : 0}
												</TableCell>
												<TableCell className="font-b">
													{headerData?.llp_limiter > 0 ? Comma_format(parseInt(headerData?.llp_limiter)) : headerData?.llp_limiter === null ? 'N/A' : 0}
												</TableCell>
												<TableCell className="font-b">
													{headerData?.soft_time_limiter > 0 ? Comma_format(parseInt(headerData?.soft_time_limiter)) : headerData?.soft_time_limiter === null ? 'N/A' : 0}
												</TableCell>
												<TableCell className="font-b">
													{headerData?.current_operating_thrust?.name ? headerData?.current_operating_thrust?.name : " -- "}
												</TableCell>
											</TableRow>
										</TableBody>}
										{assetType == 2 && <TableHead className="table-head">
											<TableRow>
												<TableCell>Reporting Month</TableCell>
											</TableRow>
										</TableHead>}
										{assetType == 2 && <TableBody>
											<TableRow>
												<TableCell className="font-b">	
													{headerData?.last_utilization_updated ? moment(headerData?.last_utilization_updated).format(dayMonthDateFormat) : " -- "}
												</TableCell>
											</TableRow>
										</TableBody>}
									</Table>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				</div>
				{assetType == 2 && <div id="AmpTableHeader">
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon className="p-5" />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>Technical Specification</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container>
								<Grid
									className="left-panel"
									item
									xs={12}
									style={{ backgroundColor: " #efefef" }}
								>
									<Table className="table-wrapper">
										<TableHead>
											<TableRow>
												<TableCell>A/C Model</TableCell>
												<TableCell>Operator (Lessee)</TableCell>
												<TableCell>Operator (Sub-Lessee)</TableCell>
												<TableCell>Owner of A/C </TableCell>
												<TableCell>Lessor</TableCell>
												<TableCell>Delivery Date</TableCell>
												<div id="edit-basic-detail" className="d-flex">
													<span class="editBasicDetail">
														{/* {permissionCheckFnforCamo({ primaryKey: "asset_management", keyIndex: "U", }) ? */}
														{/* {checkPermission('camo','asset_management','U') ? */}
															<Tooltip title="Edit">
																<IconButton
																	className="edit-asset-icon"
																	onClick={() => this.setState({ openEditFormEngine: true, formTitle: "Update Technical Specification" })}
																>
																	<CreateOutlinedIcon />
																</IconButton>
															</Tooltip>
														{/* ) : null} */}
													</span>
												</div>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.aircraft_model || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.lessee?.name || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.operator?.name || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.owner_aircraft || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.lessor_name?.name || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.delivery_date_to_current_operator ? moment(basicDetailData?.delivery_date_to_current_operator).format(dayMonthDateFormat) : "--"}
												</TableCell>
											</TableRow>
										</TableBody>
										<TableHead className="table-head">
											<TableRow>
												<TableCell>Monthly EFH</TableCell>
												<TableCell>Monthly EFC</TableCell>
												<TableCell>Date of Purchase</TableCell>
												<TableCell>Next Delivery Date</TableCell>
												<TableCell>Location (A/C Reg.)</TableCell>
												<TableCell>Location (Position)</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell className="font-b">
													{this.props.assetsDetail?.sort((a, b) => b - a).map(filterId => filterId?.monthly_FH)[0] || basicDetailData?.average_monthly_hours || "--"}
												</TableCell>
												<TableCell className="font-b">
													{this.props.assetsDetail?.sort((a, b) => b - a).map(filterId => filterId?.monthly_FC)[0] || basicDetailData?.average_monthly_cycles || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.purchase_date ? moment(basicDetailData?.purchase_date).format(dayMonthDateFormat) : "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.next_delivery_date ? moment(basicDetailData?.next_delivery_date).format(dayMonthDateFormat) : "--"}
												</TableCell>
												<TableCell className="font-b">
													{this.props.assetsDetail?.sort((a, b) => b - a).map(filterId => filterId?.location_reg_aircraft)[0] || basicDetailData?.new_tec_spec?.location_reg_aircraft || "--"}
												</TableCell>
												<TableCell className="font-b">
													{this.props.assetsDetail?.sort((a, b) => b - a).map(filterId => filterId?.location_position?.label)[0] || basicDetailData?.position?.label || "--"}
												</TableCell>
											</TableRow>
										</TableBody>
										<TableHead className="table-head">
											<TableRow>

												<TableCell>Location (Country)</TableCell>
												<TableCell>Operating Region</TableCell>
												<TableCell>Operating Enviroment</TableCell>
												<TableCell>Date of Installation</TableCell>
												<TableCell>Date of Removal</TableCell>
												<TableCell>Avg. Flight Ratio</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell className="font-b">
													{this.props.assetsDetail?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] || basicDetailData?.country || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.operating_region?.name || "--"}
												</TableCell>
												<TableCell className="font-b"> {basicDetailData?.new_tec_spec?.operating_environment || "--"} </TableCell>
												<TableCell className="font-b">
													{basicDetailData?.date_of_installation ? moment(basicDetailData?.date_of_installation).format(dayMonthDateFormat) : "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.date_of_removal ? moment(basicDetailData?.new_tec_spec?.date_of_removal).format(dayMonthDateFormat) : "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.avg_flight_ratio ? (basicDetailData?.avg_flight_ratio).toFixed(2) : "--"}
												</TableCell>
											</TableRow>
										</TableBody>
										<TableHead className="table-head">
											<TableRow>
												<TableCell>Engine Stand Prime P/N</TableCell>
												<TableCell>Engine Stand Prime S/N</TableCell>
												<TableCell>Engine Stand Base P/N</TableCell>
												<TableCell>Engine Stand Base S/N</TableCell>
												<TableCell>Engine Stand Cradle P/N</TableCell>
												<TableCell>Engine Stand Cradle S/N</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.engine_stand_serial_number || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.engine_stand_part_number || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.engine_stand_base_pn || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.engine_stand_base_sn || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.engine_stand_cradle_pn || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.engine_stand_cradle_sn || "--"}
												</TableCell>
											</TableRow>
										</TableBody>
										<TableHead className="table-head">
											<TableRow>
												<TableCell>Engine Stand Location</TableCell>
												<TableCell>Asset owner</TableCell>
												<TableCell>QEC Config.</TableCell>
												<TableCell>QEC P/N</TableCell>
												<TableCell>Stand</TableCell>
												<TableCell>Bag</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.engine_stand_location || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.owner?.name || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.qec_config || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.qec_part_number || "--"}
												</TableCell>

												<TableCell className="font-b"> {basicDetailData?.new_tec_spec?.stand || "--"} </TableCell>
												<TableCell className="font-b"> {basicDetailData?.new_tec_spec?.bag || "--"} </TableCell>
											</TableRow>
										</TableBody>
										<TableHead className="table-head">
											<TableRow>
												<TableCell>Sub Status</TableCell>
												<TableCell>Lease Start Date</TableCell>
												<TableCell>Lease Term Date</TableCell>
												<TableCell>Redelivery Date</TableCell>
												<TableCell>Month Remaining</TableCell>
												<TableCell>On/Off Lease Status</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell className="font-b">
													{basicDetailData?.sub_status?.label || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.lease_start_date ? moment(basicDetailData?.new_tec_spec?.lease_start_date).format(dayMonthDateFormat) : "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.lease_term_date ? moment(basicDetailData?.new_tec_spec?.lease_term_date).format(dayMonthDateFormat) : "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.redelivery_date ? moment(basicDetailData?.new_tec_spec?.redelivery_date).format(dayMonthDateFormat) : "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.month_remaining >= 0 && basicDetailData?.new_tec_spec?.month_remaining || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.status?.label || "--"}
												</TableCell>
											</TableRow>
										</TableBody>
										<TableHead className="table-head">
											<TableRow>
												<TableCell>SV Forecast Date</TableCell>
												<TableCell>EEC Software P/N </TableCell>
												<TableCell>EEC Software Version</TableCell>
												<TableCell>Monthly Use Fee</TableCell>
												<TableCell>Monthly LLP Fee</TableCell>
												<TableCell>Accumulated MR</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.sv_forecast_date ? moment(basicDetailData?.new_tec_spec?.sv_forecast_date).format(dayMonthDateFormat) : "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.eec_software_part_number || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.eec_software_version || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.monthly_use_fee || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.monthly_llp_fee || "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.accumulated_mr || "--"}
												</TableCell>
											</TableRow>
										</TableBody>
										<TableHead className="table-head">
											<TableRow>
												<TableCell>Preservation Date</TableCell>
												<TableCell>Preservation Expiry Date</TableCell>
												<TableCell>Avg. Derate %</TableCell>
												<TableCell>SV Interval Remaining</TableCell>
												<TableCell>MCBR</TableCell>
												<TableCell>MTBR</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.preservation_date ? moment(basicDetailData?.new_tec_spec?.preservation_date).format(dayMonthDateFormat) : "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.preservation_expiry_date ? moment(basicDetailData?.new_tec_spec?.preservation_expiry_date).format(dayMonthDateFormat) : "--"}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.avg_derate || "--"}
												</TableCell>
												<TableCell className="font-b">
													{ basicDetailData?.new_tec_spec?.sv_interval_remaining === null ? "--" :basicDetailData?.new_tec_spec?.sv_interval_remaining > 0 ? basicDetailData?.new_tec_spec?.sv_interval_remaining : '0'}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.smbc_forecast_mcbr || '--'}
												</TableCell>
												<TableCell className="font-b">
													{basicDetailData?.new_tec_spec?.smbc_forecast_mtbr || '--'}
												</TableCell>
											</TableRow>
										</TableBody>
										<EngOnOffWingList basicDetailData={basicDetailData} />
										<TableHead className="table-head">
											<TableRow> <TableCell>Engine Stand Color</TableCell> </TableRow>
											<TableBody>
												<TableRow>
													<TableCell className="font-b"> {basicDetailData?.new_tec_spec?.engine_stand_color || '--'} </TableCell>
												</TableRow>
											</TableBody>
										</TableHead>
									</Table>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				</div>}
				<Dialog
					position="relative"
					maxWidth="lg"
					open={this.state.openEditForm}
					onClose={this.closeUpdateForm}
					style={{ height: isMobile && "650px" }}

				>
					<BasicDetailForm
						props={this.props}
						closeUpdateForm={() => this.closeUpdateForm()}
						fetchMntGrops={(aircraftId) =>
							this.props.getAircraftMntProgram(aircraftId)
						}
						aircraftMntGrp={aircraftMntGrp}
						leaseStatus={leaseStatus}
						subStatus={subStatus}
						portfolios={portfolios}
						ownersList={ownersList}
						lessees={lessees}
						operators={operators}
						pltConstants={pltConstants}
						aircraftTypes={aircraftTypes}
						headerData={headerData}
						asset={asset}
						LHData={this.state.LHData}
						RHData={this.state.RHData}
						assetType={assetType}
					/>
				</Dialog>
				<Dialog
					position="relative"
					maxWidth="lg"
					open={this.state.openEditFormEngine}
					onClose={() => this.setState({ openEditFormEngine: false })}
					style={{ height: isMobile && "650px" }}
				>
					<EngineDetailForm
						closeUpdateForm={() => this.setState({ openEditFormEngine: false })}
						slug={headerData.slug}
						basicDetailData={basicDetailData}
						getResponseBack={() => this.getTechSpecDetailApi(headerData)}
						assetDetail={headerData}
						assets={this.props.assetsDetail}
						svData={svData}
						pltConstants={pltConstants}
					/>
				</Dialog>
			</Fragment>

		)
	}
}

const mapStateToProps = (state) => ({
	aircraftTypes: state.shareReducer.aircraftTypes,
	engineTypes: state.shareReducer.engineTypes,
	lessees: state.shareReducer.lessees,
	operators: state.shareReducer.operators,
	regions: state.shareReducer.regions,
	portfolios: state.shareReducer.portfolios,
	pltConstants: state.shareReducer.pltConstants,
	aircraftMntGrp: state.shareReducer.aircraftMntGrp,
	leaseStatus: state.shareReducer.leaseStatus,
	subStatus: state.shareReducer.subStatus,
})
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getAircraftTypes: (props, query) =>
			dispatch(getAircraftTypesAc(props, query)),
		getRegionList: () => dispatch(getRegionListAc()),
		getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
		getLesseeList: () => dispatch(getLesseeListAc()),
		getOperatorList: () => dispatch(getOperatorListAc()),
		getPortfolioList: () => dispatch(getPortfolioListAc()),
		getAircraftMntProgram: (aircraftId) =>
			dispatch(getAircraftMntProgramAc(aircraftId)),
		getPlatformConstants: () =>
			dispatch(getPlatformConstantsAc({ constant_types: ["asset_status", "credit_classification", "engine_position", "lg_position","off_wing_status","shop_visit_type","workscope_status","sv_report_status","sv_requirement"], })),
		getLeaseStatus: () => dispatch(getLeaseStatus({ constant_types: ["lease_status"] })),
		getSubStatus: () =>
			dispatch(getSubStatus({ constant_types: ["asset_sub_status"] })),
	}
}

export default withSnackbar(
	connect(mapStateToProps, mapDispatchToProps)(BasicDetailHeader)
)
