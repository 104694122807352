import config from "../../config";
import { getLocalStorageInfo } from "../../utils";

export const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532) 
export const camoLeads = getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')

export const specificKey =['NA', 'N/A', 'UNKNOWN', 'UNK']
export const specificMessTsn = "Allow Keywords NA, N/A,Unknown,UNK or 0 to 99999.99";
export const specificMessCsn = "Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999";

export const engineDetailObj = {
    location: null,
    operator: null,
    lessee: null,
    position: null,
    lessor_name: null,
    delivery_date_to_current_operator: null,
    average_monthly_hours: null,
    average_monthly_cycles: null,
    purchase_date: null,
    next_delivery_date: null,
    country: null,
    region: null,
    date_of_installation: null,
    tslsv: null,
    cslsv: null,
    owner: null,
    sub_status: null,
    status: null,
    llp_limiter: null,
    Soft_time_limiter: null,
    avg_flight_ratio: null,
    new_tec_spec: {
        aircraft_model: null,
        owner_aircraft: null,
        location_reg_aircraft: null,
        date_of_removal: null,
        avg_derate: null,
        engine_stand_part_number: null,
        engine_stand_serial_number: null,
        engine_stand_location: null,
        qec_config: null,
        qec_part_number: null,
        min_llp_prior_to_term_date: null,
        lease_start_date: null,
        lease_term_date: null,
        redelivery_date: null,
        eec_software_part_number: null,
        eec_software_version: null,
        monthly_use_fee: '',
        monthly_llp_fee: '',
        accumulated_mr: '',
        month_remaining: 0,
        preservation_date: null,
        preservation_expiry_date: null,
        bag: null,
        stand: null,
        engine_stand_base_sn: null,
        engine_stand_base_pn: null,
        engine_stand_cradle_sn: null,
        engine_stand_cradle_pn: null,
        operating_region: null,
        sv_internal_remaining:null,
        smbc_forecast_mcbr:null,
        smbc_forecast_mtbr:null,
        toggle_mtbr_mcbr:false,
        engine_stand_color:null
    }

}

export const offWingObj = {
    tsn_at_removal: null,
    csn_at_removal: null,
    date_of_removal: null,
    reason_for_removal: '',
    preservation_performed: false,
    release_date_of_the_engine: null,
    shop_visit_report_provided: false,
    type_of_shop_visit: '',
    expected_date_of_shipment_back_to_lessee: null,
    expected_release_date_of_the_engine: null,
    induction_date_of_engine: null,
    work_scope_status: '',
    shop_visit_name: '',
    shop_visit_location: '',
    shop_visit_requirement: '',
    expected_date_of_shipment_to_shop: null,
    selected_shop: '',
    off_wing_location: '',
}
export const onWingObj = {
    titled_aircraft: '',
    other_aircraft_msn: ''
}
