import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { Grid, Paper } from '@material-ui/core';
import { LeaseStatusGraph, OnOffWingGraph, LimiterDue, ShopVisitChart, FleetAd, FleetSb } from '../components';
import UnderlyingChartData from '../components/UnderlyingChartData';

const DashboardAnalytics = () => {
    const [open, setOpen] = useState({ modal: false, data: null, type: "", per_page: null })
    const underlyingData = (name, type) => {
        setOpen({ modal: true, data: name, type: type, })
    }
    return (
        <section>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                    <Paper style={{ borderRadius: 0 }}>
                        <LeaseStatusGraph underlyingData={underlyingData} />
                    </Paper>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Paper style={{ borderRadius: 0 }}>
                        <OnOffWingGraph underlyingData={underlyingData} />
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                    <Paper  style={{ borderRadius: 0 }}>
                        <ShopVisitChart underlyingData={underlyingData} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper square elevation={2}> <LimiterDue /> </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Paper square elevation={2}> <FleetAd /> </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper square elevation={2}> <FleetSb /> </Paper>
                </Grid>
            </Grid>
            {
                open?.modal ?
                    <UnderlyingChartData
                        onClose={() => setOpen(false)}
                        assetData={open}
                    /> : null
            }
        </section >

    );
};

export default withRouter(DashboardAnalytics);
