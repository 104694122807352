import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { globalGetService } from '../../../utils/globalApiServices';
import Highcharts, { color } from 'highcharts';
import { Skeleton } from '@material-ui/lab';
import { Grid, Paper } from '@material-ui/core';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

const LeaseStatusGraph = ({ underlyingData }) => {
    const [pageLoader, setPageLoader] = useState(false);
    const [skeltonLoader, setSkeltonLoader] = useState(false);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        smbcChartList();
    }, []);

    const smbcChartList = (query, loader) => {
        loader === "pageLoader" ? setPageLoader(true) : setSkeltonLoader(true);
        globalGetService(`/camo/smbc_dashboard/asset/`, { ...query })
            .then((response) => {
                setChartData(response.data);
                loader === "pageLoader" ? setPageLoader(false) : setSkeltonLoader(false);
            }).catch((error) => {
                console.error('Error fetching chart data:', error);
                setPageLoader(false);
                setSkeltonLoader(false);
            });
    }

    const options = {
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 25
            },
        },
        title: {
            text: '',
        },
        colors: ["#ffb98d", "#beed87", "#89bfff", "#d7d7d7", "#729cf1"],
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 60,
                showInLegend: false,
                allowPointSelect: true,
                cursor: 'pointer',
                animation: false,
                dataLabels: {
                    enabled: true,
                    softConnector: false
                },
                point: {
                    events: {
                        click: function (oEvent) {
                            underlyingData(oEvent.point.name, 'lease');
                        }
                    }
                },
            },

        },
        series: [
            {
                name: 'Count',
                data: chartData?.engine_status.map(item => [item?.status, item?.count]),
                colorByPoint: true,
            },
        ],
        credits: {
            enabled: false,
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG', 'downloadPDF'], 
                },
            },
            chartOptions: {
                title: {
                    text: "Lease Status of the Assets" 
                },
            },
            filename: 'Lease_Status_Chart', 
        },
    }
    return (
        <>
            {skeltonLoader ? (
                <Skeleton variant="rect" animation='wave' height={400} />
            ) :
                <Paper className="dashboard-chart" style={{ borderRadius: 0 }}>
                    <div className='dashboard-chart-title' style={{padding:'15px'}}>
                        <h5>Lease Status of the Assets</h5>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </Paper>
            }


        </>
    );
};

export default withRouter(LeaseStatusGraph);