import React, { Fragment, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { convertFilterObject, removeEmptyKey, permissionCheckFnforCamo, } from "../../../../utils";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AddIcon from "@material-ui/icons/Add";
import {DropzoneArea} from 'material-ui-dropzone';
import {Grid, Button, Dialog, TextField, Menu, MenuItem, Tooltip, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { DownloadSvSampleFile } from "../apiServices";
import { isMobile } from "react-device-detect";
import DownloadXLSAssetDetail from "../../../../shared_elements/DownloadXLSAssetDetail";
import UtilizationFormData from "./UtilizationFormData";
import DownloadSample from "../../../../shared_elements/DownloadSample";
import DownloadPDFAssetDetail from "../../../../shared_elements/components/DownloadPDFAssetDetail";

const UtilizationSearchBar = ({ applyingFilter, tabIndex, pageLoader, props, searchData, fileUploadData, importFile, exportReportFn, currentRecordPerPage, uuid, getResponseBack, engineType, assetsDetail, basicDetails, techspecData }) => {
  const [open, setOpen] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [actionType, setActionType] = useState("");
  const [exportAnchorEl, exportSetAnchorEl] = useState(null);
  const openExportMenu = Boolean(exportAnchorEl);
  const [modal, setModal] = useState(false);
  const utilizationFH = assetsDetail.map((item) => item?.monthly_FH)
  const utilizationFC = assetsDetail.map((item) => item?.monthly_FC)
  const [importmodal, setImportModal] = useState({modal:false,data:null});
  const [values, setvalue] = useState(null);
  const [files, setFiles] = useState([]);
  const thrustMess = assetsDetail.map((item) => item?.current_operating_thrust)[1]?.name === null ? assetsDetail.map((item) => item?.utilization_period)[1]?.data :
    assetsDetail.map((item) => item?.current_operating_thrust)[0]?.name === null && assetsDetail.map((item) => item?.utilization_period)[0]?.data

  const addNewTask = (key,value) => {
    if (key === 'import') {
      if ((utilizationFH[1] === '0.00') || (utilizationFH[0] === '0.00')) {
        setModal(true)
        setvalue(value)
      } else if (utilizationFC[0] === 0 || utilizationFC[1] === 0) {
        setModal(true)
        setvalue(value)
      } else if (assetsDetail.map((item) => item?.current_operating_thrust)[1]?.name === null || assetsDetail.map((item) => item?.current_operating_thrust)[0]?.name === null) {
        setModal(true)
        setvalue(value)
      }
    } else {
      if (assetsDetail?.length === 0) {
        setOpen(true);
        setFormTitle("Utilization");
        setActionType("add");
        setvalue('')
      } else if ((utilizationFH[1] === '0.00') || (utilizationFH[0] === '0.00')) {
        setModal(true)
      } else if (utilizationFC[0] === 0 || utilizationFC[1] === 0) {
        setModal(true)
      } else if (assetsDetail.map((item) => item?.current_operating_thrust)[1]?.name === null || assetsDetail.map((item) => item?.current_operating_thrust)[0]?.name === null) {
        setModal(true)
      } else {
        setOpen(true);
        setFormTitle("Utilization");
        setActionType("add");
      }
    }

  };

  const closeAddForm = () => {
    setOpen(false);
  };

  const reOpen=(key)=>{
    if (key === 'value') {
        setImportModal({modal:true})
      }else{
       setOpen(true);
       setFormTitle("Utilization");
       setActionType("add");
       setvalue('')
    }
  }

  const handleDropzoneChange = (e) => {
    setFiles(e)
  };

  const onExportHandleClose = () => {
    exportSetAnchorEl(null);
  };

  const onExportHandleClick = (event) => {
    exportSetAnchorEl(event.currentTarget);
  };
 
  const handleFileUpload = () => {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i]);
      }
       const fileType = files[0].type;
      if (!fileType.endsWith("vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
        formData.append("file", files[0]);
        importFile(formData, props, false);
        setImportModal({modal:false})
      } else {
        formData.append("file", files[0]);
        importFile(formData, props, true);
        setImportModal({modal:false})
      }
     
  };

  return (
    <Fragment>
      <div id="AmpSearchBar">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="search-input">
              <TextField
                id="search"
                placeholder="Search By Location(A/C Reg) Location (Country) Reason Of Removal "
                onChange={searchData}
              />
              <Tooltip
                title="Search By Location(A/C Reg) Location (Country) Reason Of Removal"
                variant="contained"
              >
                <SearchIcon className="search-icon" />
              </Tooltip>
            </div>
          </div>
          <div className="btn-wrapper">
            {permissionCheckFnforCamo({
              primaryKey: "occm_component",
              keyIndex: "C",
            }) ? (
              <Tooltip title="ADD UTILIZATION">
                <Button
                  onClick={addNewTask}
                  variant="contained"
                  color="primary"
                >
                  <AddIcon style={{ marginTop: "-1px" }} className="add-icon" />
                  ADD UTILIZATION
                </Button>
              </Tooltip>
            ) : null}
            <Button variant="outlined" color="primary" component="label">
              {utilizationFH[0] === '0.00' || utilizationFH[1] === '0.00' || utilizationFC[0] === 0 || utilizationFC[1] === 0
                || assetsDetail.map((item) => item?.current_operating_thrust)[1]?.name === null || assetsDetail.map((item) => item?.current_operating_thrust)[0]?.name === null ?
                <span onClick={() => addNewTask('import','value')}>IMPORT</span>
                : <><span onClick={() => setImportModal({modal:true})}>IMPORT</span>
                  </>
                  }
            </Button>
            <Button
              variant="outlined"
              color="primary"
              id="basic-button"
              aria-controls={openExportMenu ? "basic-menu" : ""}
              aria-haspopup="true"
              aria-expanded={openExportMenu ? "true" : ""}
              onClick={onExportHandleClick}
            >
              EXPORT
              <KeyboardArrowDownIcon style={{ marginTop: "-1px" }} />
            </Button>
            <Menu
              className="import-menu-item export"
              id="basic-menu"
              anchorEl={exportAnchorEl}
              open={openExportMenu}
              onClose={onExportHandleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem className="export-list">
                <DownloadXLSAssetDetail
                  files={{ title: "EXCEL", extension: "xls", key: "" }}
                  exportReportFn={exportReportFn}
                />
              </MenuItem>
              <MenuItem className="export-list">
                <DownloadPDFAssetDetail
                  files={{ title: "PDF", extension: "pdf", key: "" }}
                  exportReportFn={exportReportFn}
                />
              </MenuItem>
            </Menu>
            <DownloadSample
              variant="outlined"
              color="primary"
              files={{ title: "DOWNLOAD SAMPLE", extension: "xlsx", key: "" }}
              exportSample={(file) => DownloadSvSampleFile( { download: file.extension, ...{ ...convertFilterObject( removeEmptyKey(applyingFilter), ), asset_type: tabIndex, }, }, pageLoader, props,basicDetails ) }
            />
            <Dialog open={open} style={{ height: isMobile && "650px" }}>
              <UtilizationFormData
                formTitle={formTitle}
                closeAddForm={() => closeAddForm()}
                props={props}
                actionType={actionType}
                fileUploadData={fileUploadData}
                currentRecordPerPage={currentRecordPerPage}
                uuid={uuid}
                getResponseBack={() => getResponseBack()}
                engineType={engineType}
                assetsDetail={assetsDetail}
                basicDetails={basicDetails}
                techspecData={techspecData}
              />
            </Dialog>
          </div>
        </div>
      </div>
      {
        modal ?
          <Dialog
            open={modal}
            onClose={() =>{ setModal(false);setvalue('')}}
            className="utilization-addBlock-modal"
          >
            <DialogContent>
              {utilizationFH[0] === '0.00' || utilizationFH[1] === '0.00' || utilizationFC[0] === 0 || utilizationFC[1] === 0 ? <p>Value of Monthly FH, FC should not be zero  <b>{(utilizationFC[1] === 0 || utilizationFH[1] === '0.00') ?
                assetsDetail.map((item) => item?.utilization_period)[1]?.data : (utilizationFC[0] === 0 || utilizationFH[0] === '0.00') ? assetsDetail.map((item) => item?.utilization_period)[0]?.data : '--'}</b>.</p> : null}
              {assetsDetail.map((item) => item?.current_operating_thrust)[1]?.name === null || assetsDetail.map((item) => item?.current_operating_thrust)[0]?.name === null ? <p>Operating Thrust (Current) Should not be blank  <b>{thrustMess}</b>.</p> : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { setModal(false); reOpen( values ? values :'addagain') }} variant='contained'  size="small"  color='primary' >Continue</Button>
            </DialogActions>
          </Dialog> : null
      }
      {importmodal?.modal ?
        <Dialog
          open={importmodal?.modal}
          //  onClose={() => setImportModal(false)}
          className="utilization-addBlock-modal"
        >
          <DialogTitle>Upload File</DialogTitle>
          <DialogContent dividers={true}>
            <DropzoneArea
              acceptedFiles={[".xlsx",]}
              filesLimit={1}
              maxFileSize={104857600}
              showPreviewsInDropzone={false}
              useChipsForPreview={true}
              showPreviews={true}
              dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /> </p>}
              dropzoneClass="drag-drop-cnt"
              maxWidth="sm"
              showAlerts={['info', 'error']}
              alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
              clearOnUnmount={true}
              onChange={handleDropzoneChange}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => { setImportModal(false); setvalue('')}} variant='outlined' size="small" color='secondary' >Cancel</Button>
            <Button onClick={handleFileUpload}  disabled={ files.length ?  false:true } color="primary"  size="small" variant='contained'>Save</Button>
          </DialogActions>
        </Dialog> : null
      }
    </Fragment>
  );
};
export default UtilizationSearchBar