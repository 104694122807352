import React, { useEffect, useState } from 'react'
import { Button, Grid, Paper, TextField } from '@material-ui/core';
import { globalGetService } from '../../../utils/globalApiServices';
import { withRouter, Link } from "react-router-dom";
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Autocomplete, } from '@material-ui/lab'
import { Skeleton } from '@material-ui/lab';
import { PageLoader, EmptyCollection } from "../../../shared_elements"

function FleetSb({ match, location }) {
    const [skeltonLoader, setSkeltonLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false)
    const [fleetSb, setFleetSb] = useState({});
    const [yearsData, setYearsData] = useState({});
    const [selectedYear, setselectedYear] = useState({});

    useEffect(() => {
        fleetSbListing({ per_page: 50, year_view: true })
    }, [])

    const fleetSbListing = (query, loader) => {
        loader === "pageLoader" ? setPageLoader(true) : setSkeltonLoader(true);
        globalGetService(`/camo/smbc_dashboard/sb_due/`, { ...query })
            .then((response) => {
                setFleetSb(response.data);
                let minYear = response.data.year?.min_year
                let maxYear = response.data.year?.max_year
                let yearMenu = []
                for (let i = minYear; i <= maxYear; i++) {
                    yearMenu.push({ label: i.toString(), value: i.toString() })
                }
                setYearsData(yearMenu)
                setselectedYear(response.data.year?.year)
                loader === "pageLoader" ? setPageLoader(false) : setSkeltonLoader(false);
            }).catch((error) => {
                console.error('Error in fetching data:', error);
                setPageLoader(false);
                setSkeltonLoader(false);
            });
    }

    const renderYearData = (yearData, year) => {
        if (yearData && yearData.length > 0) {
            return yearData.map((item, index) => (
                <Link to={`${item.asset_id}/${item?.asset_type}/sb/list?record_id=${item?.record_id}`}
                    target="_new"><p className="cal-table-row" key={index}>{item?.engine_type + ", " + " ESN :" + item?.esn + " "}@ <b>{" " + item?.due_date ? moment(item?.due_date).format('MMM-YYYY') : null}</b> </p></Link>
            ));
        } else {
            return <span>No data available</span>;
        }
    };

    return (
        <>{skeltonLoader ? (
            <Skeleton variant="rect" animation='wave' height={400} />
        ) : <>
            <Paper className="dashboard-chart" style={{ borderRadius: 0 }}>
                <Grid container spacing={0} >
                    <Grid item xs={12} sm={5} className='dashboard-chart-title' style={{padding:'15px'}}> <h5> Fleet SB Due</h5> </Grid>
                    <Grid item xs={12} sm={7} className='dashboard-chart-title'>
                        <ul className='list-inline' style={{ float: 'right' }}>
                            {selectedYear === "monthly_year" ? <li className='list-inline-item' style={{ marginRight: '10px' }}>
                                <Autocomplete
                                    options={yearsData}
                                    getOptionLabel={option => option.label}
                                    disableClearable={true}
                                    className='fleet-due'
                                    style={{ width: '150px' }}
                                    // value={yearsData.find(i => i.value == selectedYear.value)}
                                    onChange={(e, value) => { fleetSbListing({ per_page: 50, year_view: value?.value }, 'pageLoader') }}
                                    renderInput={params => <TextField {...params} style={{ margin: '0px' }} label="Year" placeholder="Select Year" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
                                />
                            </li> : null}
                            <li className='list-inline-item'>
                                <Button variant='outlined' color='primary' onClick={() => fleetSbListing({ per_page: 50, year_view: new Date().getFullYear() }, 'pageLoader')}>Monthly</Button>
                            </li>
                            <li className='list-inline-item' style={{ marginLeft: '10px' }}>
                                <Button variant='outlined' color='primary' onClick={() => fleetSbListing({ per_page: 50, year_view: true }, 'pageLoader')}>Yearly</Button>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container spacing={0} >
                    <Grid item xs={12} sm={12} style={{ height: "400px", overflow: "auto", margin: '10px' }}>
                        {fleetSb?.data ? Object.keys(fleetSb?.data).map(year => (
                            <Paper elevation={8} square key={year} style={{ height: "140px", overflow: "auto", padding: '0px 0px 0px 10px', border: "1px solid #eaeaea" }}>
                                <h3 style={{ padding: '5px' }}><b>{year}</b></h3>
                                {renderYearData(fleetSb?.data[year], year)}
                            </Paper>
                        )) : <EmptyCollection />}
                    </Grid>
                </Grid>
            </Paper>
        </>}
            {pageLoader ? <PageLoader /> : null}
        </>
    )
}

export default withRouter(FleetSb);