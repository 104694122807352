import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import {Paper } from '@material-ui/core';
import { globalGetService } from '../../../utils/globalApiServices';
import Highcharts from 'highcharts';
import { Skeleton } from '@material-ui/lab';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

const OnOffWingGraph = ({ underlyingData }) => {
    const [pageLoader, setPageLoader] = useState(false);
    const [skeltonLoader, setSkeltonLoader] = useState(false);
    const [onWing, setOnWing] = useState(null);


    useEffect(() => {
        smbcOnOfWing()
    }, []);

    const smbcOnOfWing = (query, loader) => {
        loader === "pageLoader" ? setPageLoader(true) : setSkeltonLoader(true);
        globalGetService(`camo/smbc_dashboard/on_off/`, { ...query })
            .then((response) => {
                setOnWing(response.data);
                loader === "pageLoader" ? setPageLoader(false) : setSkeltonLoader(false);
            }).catch((error) => {
                console.error('Error fetching chart data:', error);
                setPageLoader(false);
                setSkeltonLoader(false);
            });
    }


    const OnOffOptions = {
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 45,
            },
        },
        title: {
            text: '',
            // text: 'On/ Off Wing Status',
        },
        colors: ["#1b47a6", "#f2ce2e", "#eb5f52", "#cca756", "#729cf1"],
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 45,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `${this.point.name} (${this.y})`;
                    },
                },
                showInLegend: true,
                point: {
                    events: {
                        click: function (oEvent) {
                            underlyingData(oEvent.point.name, 'on-Off');
                        }
                    }
                },
            },
        },
        series: [
            {
                name: 'Count',
                data: [['On Wing', onWing?.on_wings_status], ['In Shop Serviceable', onWing?.in_shop_serviceable], ['In Shop UnServiceable', onWing?.in_shop_unserviceable], ['Off Wing Serviceable', onWing?.off_wing_status_serviceable], ['Off Wing UnServiceable', onWing?.off_wing_status_unserviceable]],
                colorByPoint: true,
            },
        ],
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            filename: 'On_Off_Wing_Status',
            chartOptions: {
                title: {
                    text: 'On/ Off Wing Status', 
                },
            },
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG', 'downloadPDF'],
                },
            },
        },
    }
    return (
        <>
            {skeltonLoader ? (
                <Skeleton variant="rect" animation='wave' height={400} />
            ) :
                <Paper className="dashboard-chart" style={{ borderRadius: 0 }}>
                    <div className='dashboard-chart-title' style={{padding:'15px'}}>
                        <h5>On/ Off Wing Status</h5>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={OnOffOptions} />
                </Paper>
            }
        </>
    );
};

export default withRouter(OnOffWingGraph);